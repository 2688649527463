import React from 'react'
import './index.less'
import { Button } from 'antd-mobile'
import find from 'lodash/find'
const customTable = (props) => {
  const bodyTrEle = () => {
    if (props.tableData.length > 0) {
      let tableTr = []
      if (find(props.columns, { key: 'action' })) {
        tableTr = props.tableData.map((data, index) => {
          return (
            <tr
              style={{
                textAlign: 'center'
              }}
              className='tr'
              key={index}
            >
              <td>{data.name}</td>
              <td>{data.num}</td>
              <td>{data.mobile}</td>
              <td>{data.time}</td>
              <td>
                <Button
                  color='primary'
                  size='mini'
                  onClick={() => cancel(data)}
                >
                  取消
                </Button>
              </td>
            </tr>
          )
        })
      } else {
        tableTr = props.tableData.map((data, index) => {
          return (
            <tr className='tr' key={index}>
              <td>{data.name}</td>
              <td>{data.num}</td>
              <td style={{ width: '110px' }}>{data.mobile}</td>
              <td style={{ width: '150px' }}>{data.time}</td>
            </tr>
          )
        })
      }

      return tableTr
    } else {
      return null
    }
  }
  // 取消预约
  const cancel = (data) => {
    props.cancel(data)
  }
  return (
    <>
      <table border={1} className='table'>
        {find(props.columns, { key: 'action' }) ? (
          <thead className='head'>
            <tr>
              {props.columns.map((column) => {
                return (
                  <th
                    style={{
                      textAlign: column.headerAlign || 'left',
                      width: column.width + 'px'
                    }}
                    className='head'
                    key={column.key}
                  >
                    {column.label}
                  </th>
                )
              })}
            </tr>
          </thead>
        ) : null}

        <tbody className='tbody'>{bodyTrEle()}</tbody>
      </table>
    </>
  )
}

export default customTable
