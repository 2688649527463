import React, { useState, useEffect } from 'react'
import { Mask, Checkbox, Button, Space  } from 'antd-mobile'
import { checkedArgessValue } from '@/store/actions/common'
import { connect } from 'react-redux'
const one = require('@/assets/1.png')
const two = require('@/assets/2.png')
const three = require('@/assets/3.png')
const four = require('@/assets/4.png')
const five = require('@/assets/5.png')
import './index.less'
const Notice = (props) =>  {
  const [visible, setVisible] = useState(props.argess)
  const [disabled, setDisabled] = useState(true)
  const [checked, setChecked] = useState(false)
  function handleChecked (value) {
    setChecked(value)
  }
  function handleSubmit () {
    if (checked && !disabled) {
      props.checkedArgessValue(false)
      setVisible(false)
    }
  }
  const [seconds, setSeconds] = useState(5)
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1)
    }, 1000)
    if (seconds <= 0) {
      // 在这里执行你的业务逻辑
      clearInterval(timer)
      setDisabled(false)
    }
    return () => {
      clearInterval(timer)
    }
  }, [seconds])

  return (
    <>
      <Mask opacity={'thick'} visible={visible}>
        <div className='mask-content'>
          <div className='dialog-area'>
            <h2 className={'main-title'}>温馨提示</h2>
            <div className='notice-container'>
              <p className='title-desc'>郑州眼健康科普馆位于<span
                  className='warning'>郑州市高新区长椿路5号新视明科技园内（地铁一号线兰寨站即可直达）</span>。于2019年6月6日揭牌启用，先后荣获<span
                  className="item">郑州市科普示范基地（2019）、</span><span
                  className="item">河南省科普教育基地（2020）、</span><span
                  className="item">河南省优秀科普教育基地（2021）、</span><span
                  className="item">河南省十佳科普教育基地（2021）荣誉、</span><span
                  className="item">郑州市中小学生科普研学基地（2022）等。</span>是一座科普眼睛知识的趣味性展馆，科技园为无烟安全生产园区，为保证您能够充分体验眼健康科普馆的乐趣。
              </p>
              <div className='warning'>入园我们有一些注意事项提醒您，请您理解!</div>
              <ol>
                <li>
                  <img src={one} alt=""/>
                  请您尽量在预约时间到达，超过预约时段10分钟预约视为无效
                </li>
                <li>
                  <img src={two} alt=""/>
                  入园无需身份证件，仅需要以您的预约姓名签到即可，请确保姓名、电话等预留信息的真实性，如不真实，您的预约将视为无效;成人携小朋友入园需签署安全承诺书
                </li>
                <li>
                  <img src={three} alt=""/>
                  园内禁止吸烟，我们特别为您准备了吸烟室，如您需要请告知工作人员
                </li>
                <li>
                  <img src={four} alt=""/>
                  在眼健康科普馆内，小朋友们可沉浸式体验各种趣味丛生的VR设备，为保证大家的参观体验感，我们每批次的预约人员保持在25人以内，若预约人员较多，我们会根据预约数调整参观队
                </li>
                <li>
                  <img src={five} alt=""/>
                  为保障大家的参观安全，请参观人员勿追逐、勿喧哗，保持队形，如有特别需要可告知随行的工作人员；再次感谢大家对郑州眼健康科普馆的关心与支持
                </li>
              </ol>
              <ol>
                <li>
                  <img src={one} alt=""/>
                  请您尽量在预约时间到达，超过预约时段10分钟预约视为无效
                </li>
                <li>
                  <img src={two} alt=""/>
                  入园无需身份证件，仅需要以您的预约姓名签到即可，请确保姓名、电话等预留信息的真实性，如不真实，您的预约将视为无效;成人携小朋友入园需签署安全承诺书
                </li>
                <li>
                  <img src={three} alt=""/>
                  园内禁止吸烟，我们特别为您准备了吸烟室，如您需要请告知工作人员
                </li>
                <li>
                  <img src={four} alt=""/>
                  在眼健康科普馆内，小朋友们可沉浸式体验各种趣味丛生的VR设备，为保证大家的参观体验感，我们每批次的预约人员保持在25人以内，若预约人员较多，我们会根据预约数调整参观队
                </li>
                <li>
                  <img src={five} alt=""/>
                  为保障大家的参观安全，请参观人员勿追逐、勿喧哗，保持队形，如有特别需要可告知随行的工作人员；再次感谢大家对郑州眼健康科普馆的关心与支持
                </li>
              </ol>
            </div>

            <div className='agress'>
              <Space direction='vertical' block>
                <Checkbox className={'checkBox'} checked={checked} block
                          onChange={handleChecked}>我已阅读，并同意。</Checkbox>
                {
                  seconds > 0 ? (<Button block color='primary' size={'middle'}
                                        onClick={handleSubmit}>确认{`(${seconds}s)`}</Button>) : (
                      <Button disabled={disabled} size={'middle'} block color='primary'
                              onClick={handleSubmit}>确认</Button>)
                }
              </Space>
            </div>
          </div>
        </div>
      </Mask>
    </>
  )
}

export default connect(
    (state) => ({
      argess: state.commonReducer.argess
    }),
    {
      checkedArgessValue
    }
)(Notice)
