import http from './axios'
// 预约
export const userSubscribe = (params) => {
	return http({
		url: '/api/Appt/setAppt',
		method: 'post',
		data: params
	})
}
// 获取预约列表
export const queryUserList = (params) => {
	return http({
		url: '/api/Appt/getAppt',
		method: 'post',
		data: params
	})
}
// 取消预约
export const cancelUser = (params) => {
	return http({
		url: '/api/Appt/upAppt',
		method: 'post',
		data: params
	})
}

// 查询总预约数
export const queryAllData = (params) => {
	return http({
		url: '/api/Appt/getApptList',
		method: 'post',
		data: params
	})
}
