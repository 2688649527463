import AdmissionTicketForm from '@/components/AdmissionTicketForm'
import { userSubscribe } from '@/http/api'
import { useSign } from '@/tools'
import { Button, Card, Toast } from 'antd-mobile'
import React from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import Popup from '../../components/UserInfo'
import './index.less'
const InfoImg = require('@/assets/info.png')
const Info2Img = require('@/assets/info2.png')
const verify = (props) => {
  const ChildRef = React.createRef()
  const popupRef = React.createRef()
  const date = new Date(props.visitorTimeOrDate.date)
  const chineseDate = date.toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
  const handleSubmit = () => {
    ChildRef.current
      .getFormValue()
      .then((formData) => {
        const { randomStr, sign, timeStamp } = useSign()
        userSubscribe({
          randomStr,
          sign,
          timeStamp,
          ...formData
        })
          .then((result) => {
            if (result.code == 200) {
              Toast.show({
                icon: 'success',
                content: result.msg
              })
              popupRef.current.handleInfo(formData)
              ChildRef.current.resetForm()
            } else {
              Toast.show({
                icon: 'fail',
                content: result.msg
              })
            }
          })
          .catch((error) => {
            Toast.show({
              icon: 'fail',
              content: error.msg
            })
          })
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error.msg
        })
      })
  }
  return (
    <>
      <Header title='郑州眼健康科普馆' arrow={true}></Header>
      <div className='card-area'>
        <Card
          title={
            <div className='title-container'>
              <img className='icon' src={Info2Img} alt='' />
              <span className='name'>预约信息</span>
            </div>
          }
        >
          <div className='info-one-line'>{`参观日期:${chineseDate} ${props.visitorTimeOrDate.time}`}</div>
          <div className='info-details'>
            为保证参观质量,建议单次预约人数不超过15人
          </div>
          <div className='info-details'>大型团队参观请电话咨询</div>
          <div className='info-details'>王女士:15638176976/15937000095</div>
        </Card>
      </div>
      <div className='card-area'>
        <Card
          title={
            <div className='title-container'>
              <img className='icon' src={InfoImg} alt='' />
              <span className='name'>门票信息</span>
            </div>
          }
        >
          <AdmissionTicketForm
            onRef={ChildRef}
            userData={props.visitorTimeOrDate}
          ></AdmissionTicketForm>
        </Card>
      </div>
      <div className='action'>
        <Button block color='primary' size='large' onClick={handleSubmit}>
          确认预约
        </Button>
      </div>
      <Popup onRef={popupRef} />
    </>
  )
}
export default connect((state) => ({
  visitorTimeOrDate: state.commonReducer.visitorTimeOrDate
}))(verify)
