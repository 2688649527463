import { resetSelectValue } from '@/store/actions/common'
import { Card, Popup } from 'antd-mobile'
import React, { useImperativeHandle, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './index.less'
const popup = (props) => {
  const [visible, setVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const router = useNavigate()
  const handleClose = () => {
    setVisible(false)
    props.resetSelectValue()
    router('/')
  }
  const handleInfo = (info) => {
    setVisible(true)
    setUserInfo(info)
  }
  useImperativeHandle(props.onRef, () => {
    return {
      handleInfo
    }
  })
  return (
    <Popup
      destroyOnClose
      visible={visible}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        height: '200px'
      }}
      showCloseButton
      onClose={handleClose}
    >
      <Card title='预约成功'>
        <div className='user-info'>
          <span className='title'>预约人：</span>
          <span className='content'>{userInfo.name}</span>
        </div>
        <div className='user-info'>
          <span className='title'>预约时间:</span>
          <span className='content'>{userInfo.time}</span>
        </div>
        <div className='user-info'>
          <span className='title'>参观人数:</span>
          <span className='content'>{userInfo.num}人</span>
        </div>
      </Card>
    </Popup>
  )
}
export default connect(
  (state) => ({
    visitorTimeOrDate: state.commonReducer.visitorTimeOrDate,
    selectValue: state.commonReducer.selectValue
  }),
  {
    resetSelectValue
  }
)(popup)
