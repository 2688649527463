export const basicColumns = () => {
    const list = dateLit()
    const weeks = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
    const columns = list.map((item, index) => {
        return { label: `${weeks[index]}(${item})`, value: item }
    })
    return [
        columns,
        [
            { label: '上午 10:00', value: '10:00' },
            { label: '下午 16:00', value: '16:00' }
        ]
    ]
}


import dayjs from 'dayjs'
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)
export const dateLit = () => {
    // 获取当前日期
    const now = dayjs();

// 获取本周的第一天（周一）
    const startOfWeek = now.startOf('week').format('YYYY-MM-DD');

// 计算本周的天数
    const daysInWeek = 7;

// 获取本周所有日期
    const datesInWeek = [];
    for (let i = 0; i < daysInWeek; i++) {
        const date = dayjs(startOfWeek).add(i + 1, 'day').format('YYYY-MM-DD');
        datesInWeek.push(date);
    }
    return datesInWeek
}
