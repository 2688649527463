import sha1 from 'js-sha1'
export const useSign = () => {
	const timeStamp = new Date().getTime()
	const randomStr = randomString(20)
	const token = 'appt'
	const signArr = [timeStamp, randomStr, token].sort()
	const str = signArr.join('')
	const shaStr = sha1(str)
	return { sign: shaStr.toLocaleUpperCase(), timeStamp, randomStr }
}
function randomString(e) {
	e = e || 32
	var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
		a = t.length,
		n = ''
	for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
	return n
}
