import React, { useState } from 'react'
import Header from '@/components/Header/index'
import { Button, Toast, Input } from 'antd-mobile'
import CustomTable from '../../components/CustomTable'
import { columns } from './config'
import { useSign } from '@/tools'
import { queryUserList, cancelUser } from '@/http/api'
import './index.less'
const List = () => {
	const { randomStr, sign, timeStamp } = useSign()
	const [tableData, setTableData] = useState([])
	const [mobile, setMobile] = useState('')
	const handleSearchList = () => {
		queryUserList({
			timeStamp,
			randomStr,
			sign,
			mobile: mobile
		})
			.then((result) => {
				if (result.code == 200) {
					if (result.data.length > 0) {
						setTableData(result.data)
					} else {
						setTableData(result.data)
						Toast.show({
							content: '暂无预约信息'
						})
					}
				} else {
					Toast.show({
						icon: 'fail',
						content: result.msg
					})
				}
			})
			.catch((error) => {
				Toast.show({
					icon: 'fail',
					content: error.msg
				})
			})
	}
	// 取消预约
	const handelUserCancel = (data) => {
		cancelUser({
			timeStamp,
			randomStr,
			sign,
			mobile: data.mobile,
			id: data.id
		})
			.then((result) => {
				console.log(result)
				if (result.code == 200) {
					Toast.show({
						icon: 'success',
						content: '取消成功'
					})
					handleSearchList()
				} else {
					Toast.show({
						icon: 'fail',
						content: result.msg
					})
				}
			})
			.catch((error) => {
				Toast.show({
					icon: 'fail',
					content: error.msg
				})
			})
	}
	return (
		<div className='list-container'>
			<Header title='郑州眼健康科普馆'></Header>
			<div className='search'>
				<div className='search-container'>
					<Input
						placeholder='请输入手机号查询预约信息'
						clearable
						value={mobile}
						onChange={(val) => {
							setMobile(val)
						}}
					></Input>
				</div>
				<Button block color='primary' size='middle' onClick={handleSearchList}>
					查询
				</Button>
			</div>
			<div className='table-container'>
				<CustomTable columns={columns} tableData={tableData} cancel={handelUserCancel}></CustomTable>
			</div>
		</div>
	)
}

export default List
