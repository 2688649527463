import { times } from '@/common/data'
import { updateSelectValue } from '@/store/actions/common'
import { Selector } from 'antd-mobile'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import './index.less'
const weekForm = (props) => {
  const changeValue = (value) => {
    if (value && value.length > 0) {
      const data = value.join('')
      let timeData = ''
      if (props.name === 'time') timeData = find(times, { value: data * 1 })
      console.log('timeData', timeData)
      switch (props.name) {
        case 'time':
          props.handleData({ [props.name]: timeData.label })
          props.updateSelectValue({ [props.name]: value })
          break
        case 'date':
          props.handleData({ [props.name]: data })
          props.updateSelectValue({ [props.name]: value })
          break
      }
    } else {
      switch (props.name) {
        case 'time':
          props.handleData({ [props.name]: [] })
          props.updateSelectValue({ [props.name]: [] })
          break
        case 'date':
          props.handleData({ [props.name]: [] })
          props.updateSelectValue({ [props.name]: [] })
          break
      }
    }
  }
  return (
    <Selector
      columns={3}
      defaultValue={props.defaultValue}
      options={props.options}
      onChange={changeValue}
    />
  )
}
// 组件传参校验
weekForm.propTypes = {
  options: PropTypes.array
}
export default connect(
  (state) => ({
    selectValue: state.commonReducer.selectValue
  }),
  {
    updateSelectValue
  }
)(weekForm)
