import { Picker, Space, Button } from 'antd-mobile'
import React, { useState} from 'react'
import { basicColumns } from './columns-data'
import { CloseOutline } from 'antd-mobile-icons'
export default function TimePicker(props) {
    const [value, setValue] = useState([])
    const [changeValue, setChangeValue] = useState([])
    const handleChange = (val) => {
        setChangeValue(val)
    }
    const handleSubmit = (value) => {
        setValue(value)
        props.handleSearch(changeValue)
    }
    const handleClear = () => {
        setChangeValue([])
        setValue([])
        props.handleSearch([])
    }
    return (
        <>
            <Picker
                columns={basicColumns}
                value={value}
                onConfirm={handleSubmit}
                onSelect={handleChange}
            >
                {(items, { open }) => {
                    return (
                        <Space align='center'>
                            <Button onClick={open}>选择预约日期</Button>
                            {items.every(item => item === null)
                                ? '未选择'
                                : items.map(item => item?.value ?? '未选择').join(' - ')}
                            <CloseOutline onClick={handleClear}/>
                        </Space>
                    )
                }}
            </Picker>
        </>
    )
}
