export const columns = [
	{
		label: '参观人',
		key: 'name',
		headerAlign: 'center'
	},
	{
		label: '人数',
		key: 'num',
		headerAlign: 'center'
	},
	{
		label: '预约电话',
		key: 'mobile',
		headerAlign: 'center',
		width: 110
	},
	{
		label: '预约时间',
		key: 'time',
		headerAlign: 'center',
		width: 150
	}
]
