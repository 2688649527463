import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '@/pages/home'
import Verify from '@/pages/verify'
import List from '@/pages/list'
import Gross from '@/pages/gross'
import { SafeArea } from 'antd-mobile'
import { Provider } from 'react-redux'
import { store, persisstore } from '@/store'
import { PersistGate } from 'redux-persist/integration/react'
import '@/App.less'

function App() {
	return (
		<>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persisstore}>
					<div className='page-container'>
						<div className='area-top'>
							<SafeArea position='top' />
						</div>
						<div className='page-content'>
							<Routes>
								<Route path='/' element={<Home />}></Route>
								<Route path='/verify' element={<Verify />}></Route>
								<Route path='/list' element={<List />}></Route>
								<Route path='/gross' element={<Gross />}></Route>
							</Routes>
						</div>
						<div className='area-bottom'>
							<SafeArea position='bottom' />
						</div>
					</div>
				</PersistGate>
			</Provider>
		</>
	)
}
export default App
