// import { UPDATE } from './constant'
const state = {
  visitorTimeOrDate: null,
  selectValue: null,
  argess: true
}
export default function commonReducer(preState = state, action) {
  const { type, data } = action
  switch (type) {
    case 'updateData':
      return { ...preState, visitorTimeOrDate: data }
    case 'updateSelect':
      return { ...preState, selectValue: { ...preState.selectValue, ...data } }
    case 'checkedArgess':
      return { ...preState, argess: data }
    case 'resetSelect':
      return { ...preState, selectValue: { time: [], date: [] } }
    default:
      return preState
  }
}
