import React, { useImperativeHandle, useRef } from 'react'
import { Form, Input, Stepper } from 'antd-mobile'
import './index.less'
const AdmissionTicketForm = (props) => {
	const [form] = Form.useForm()
	const formRef = useRef()
	const checkMobile = (rule, value) => {
		const reg = /^1[3-9]\d{9}$/
		if (value && reg.test(value)) {
			return Promise.resolve()
		} else if (!value) {
			return Promise.reject(new Error('手机号不能为空'))
		} else {
			return Promise.reject(new Error('手机号格式错误'))
		}
	}
	const checkIdCard = (rule, value) => {
		const reg = /^\d{17}(\d|X)$/i
		if (value && reg.test(value)) {
			return Promise.resolve()
		} else if (!value) {
			return Promise.reject(new Error('身份证号不能为空'))
		} else {
			return Promise.reject(new Error('身份证号格式错误'))
		}
	}
	const resetForm = () => {
		formRef.current.resetFields()
	}
	useImperativeHandle(props.onRef, () => {
		return {
			getFormValue,
			resetForm
		}
	})
	const getFormValue = () => {
		return new Promise((resolve, reject) => {
			formRef.current
				.validateFields()
				.then((result) => {
					const formData = {
						time: `${props.userData.date} ${props.userData.time}`,
						...result
					}
					resolve(formData)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}
	return (
		<>
			<Form ref={formRef} form={form} initialValues={{ num: 1 }} layout='horizontal'>
				<Form.Item label='参观人' name='name' rules={[{ required: true, message: '参观人名称不能为空' }]}>
					<Input placeholder='请输入个人姓名/学校名称' clearable />
				</Form.Item>
				<Form.Item label='身份证号' name='idcard' rules={[{ required: true, validator: checkIdCard }]}>
					<Input placeholder='请输入个人身份证号' clearable />
				</Form.Item>
				<Form.Item label='参观人数' name='num' rules={[{ required: true, message: '参观人数不能为空' }]}>
					<Stepper min={1} max={15} />
				</Form.Item>
				<Form.Item label='联系电话' name='mobile' rules={[{ required: true, validator: checkMobile }]}>
					<Input placeholder='请输入联系电话' clearable />
				</Form.Item>
				<Form.Item label='了解途径' name='roads'>
					<Input maxLength={10} placeholder='请输入了解途径(10字以内)' clearable />
				</Form.Item>
				<Form.Item label='参观目的' name='objective'>
					<Input maxLength={20} placeholder='请输入参观目的(20字以内)' clearable />
				</Form.Item>
			</Form>
		</>
	)
}
export default AdmissionTicketForm
