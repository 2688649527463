import React, { useState, useEffect, useRef } from 'react'
import Header from '@/components/Header/index'
import { Toast } from 'antd-mobile'
import CustomTable from '../../components/CustomTable'
import { columns } from './config'
import { useSign } from '@/tools'
import { queryAllData } from '@/http/api'
import './index.less'
import BetterScroll from 'better-scroll'
import debounce from 'lodash/debounce'
import TimePicker from "./timePicker"
const Gross = () => {
	const { randomStr, sign, timeStamp } = useSign()
	const [tableData, setTableData] = useState([])
	const [dateValue, setDateValue] = useState([])
	const [bscroll, setBscroll] = useState()
	const [totalPage, setTotalPage] = useState(1)
	let currentPage = 1
	const wrapRef = useRef(null)
	useEffect(() => {
		handleSearchList(1, 10, true, dateValue)
		initBscroll()
		return () => {
			bscroll?.destroy()
		}
	}, [])
	const initBscroll = () => {
		setBscroll(
			new BetterScroll(wrapRef.current, {
				probetype: 3,
				//  可以使用原生的点击
				click: true,
				//  检测dom变化
				observeDOM: true,
				//  鼠标滚轮设置
				mouseWheel: {
					speed: 20,
					invert: false,
					easeTime: 300
				},
				//  显示滚动条
				scrollY: true,
				scrollbar: false,
				//  过度动画, 在下载更多的时候滚动条会有个过度动画
				useTransition: true,
				//  下拉刷新
				pullDownRefresh: {
					threshold: 40,
					stop: 0
				},
				//  上拉加载更多
				pullUpLoad: {
					threshold: 20,
					stop: 10
				}
			})
		)
	}
	// 上拉加载数据
	const handlePullUp = debounce(function () {
		if (currentPage >= totalPage) {
			Toast.show('暂无更多数据')
		} else {
			handleSearchList(currentPage + 1, 10, false, dateValue)
		}
		bscroll.finishPullUp()
		bscroll.refresh()
	}, 500)
	// 下拉刷新
	const handlePullDown = debounce(function () {
		handleSearchList(1, 10, true, dateValue)
		bscroll.finishPullDown()
	}, 500)
	useEffect(() => {
		if (!bscroll) return
		const pullUp = () => {
			//判断是否滑动到了底部
			handlePullUp()
		}
		bscroll.on('pullingUp', pullUp)
		return () => {
			bscroll.off('pullingUp', pullUp)
		}
	}, [bscroll, dateValue])

	useEffect(() => {
		if (!bscroll) return
		const pullDown = () => {
			handlePullDown()
		}
		bscroll.on('pullingDown', pullDown)
		return () => {
			bscroll.off('pullingDown', pullDown)
		}
	}, [bscroll, dateValue])

	const handleSearchList = (page = 1, pagesize = 10, down, value = []) => {
		setDateValue(value)
		queryAllData({
			timeStamp,
			randomStr,
			sign,
			time: value.join(' '),
			page,
			pagesize
		})
			.then((result) => {
				if (result.code == 200) {
					if (down) {
						setTableData([...result.data])
					} else {
						if (result.z_page === 0) {
							setTableData([])
						} else {
							setTableData([...tableData, ...result.data])
						}
					}
					currentPage = page
					setTotalPage(result.z_page)
				} else {
					Toast.show({
						icon: 'fail',
						content: result.msg
					})
				}
			})
			.catch((error) => {
				console.log(error)
				Toast.show({
					icon: 'fail',
					content: error.msg
				})
			})
	}
	return (
		<>
			<Header title='郑州眼健康科普馆'></Header>
			<div className='search'>
				<div className='search-container'>
					<TimePicker handleSearch={(value) => handleSearchList(1, 10, true, value)}></TimePicker>
					{/*<Input*/}
					{/*	placeholder='请选择预约日期和时间段'*/}
					{/*	value={dateValue}*/}
					{/*	clearable*/}
					{/*	onChange={(val) => {*/}
					{/*		setDateValue(() => val)*/}
					{/*	}}*/}
					{/*></Input>*/}
					{/*<Button color='primary' size='mini' onClick={() => setVisible(true)}>*/}
					{/*	选择*/}
					{/*</Button>*/}
				</div>
				{/*<DatePicker*/}
				{/*	visible={visible}*/}
				{/*	onClose={() => {*/}
				{/*		setVisible(false)*/}
				{/*	}}*/}
				{/*	precision='minute'*/}
				{/*	onConfirm={(val) => {*/}
				{/*		setDateValue(() => dayjs(val).format('YYYY-MM-DD HH:mm'))*/}
				{/*	}}*/}
				{/*/>*/}
				{/*<Button block color='primary' size='middle' onClick={() => handleSearchList(1, 10, true)}>*/}
				{/*	查询*/}
				{/*</Button>*/}
			</div>
			<div className='table-header-container'>
				<table border={1} className='table-header'>
					<thead>
						<tr>
							{columns.map((column) => {
								return (
									<th
										style={{
											textAlign: column.headerAlign || 'left',
											width: column.width + 'px'
										}}
										className='head'
										key={column.key}
									>
										{column.label}
									</th>
								)
							})}
						</tr>
					</thead>
				</table>
			</div>
			<div ref={wrapRef} className='table-scroll-container'>
				<div className='scroll-content'>
					<CustomTable columns={columns} tableData={tableData}></CustomTable>
				</div>
			</div>
		</>
	)
}

export default Gross
