import axios from 'axios'
const http = axios.create({
  // baseURL: 'https://test.zzsmsc.com.cn',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  timeout: 10000
})
// import { useSign } from '@/tools'
// 请求拦截器
http.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    // 根据你的业务处理回调
    if (Number(response.data.code) !== 200) {
      // 处理错误
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  (error) => {
    // 对响应错误做点什么
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default http
