import dayjs from 'dayjs'
const isoWeek = require('dayjs/plugin/isoWeek')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isoWeek)
dayjs.extend(isSameOrBefore)
dayjs().isoWeekday(7)
// 判断是否超过8月15日
const handleGreaterThanTargetDate = () => {
	// 创建一个dayjs对象表示2024年8月15日
	const targetDate = dayjs('2024-08-14')
	// 创建一个dayjs对象表示当前日期
	const currentDate = dayjs()
	// 使用isAfter()方法比较两个日期
	return currentDate.isAfter(targetDate)
}
const weekData = () => {
	const greaterThanTargetDate = handleGreaterThanTargetDate()

	const list =  [
		{
			label: '星期一',
			value: dayjs().day(1).format('YYYY-MM月DD日'),
			description: dayjs().day(1).format('MM月DD日'),
			hidden: true,
			disabled: true
		},
		{
			label: '星期二',
			value: dayjs().day(2).format('YYYY-MM-DD'),
			description: dayjs().day(2).format('MM月DD日'),
			hidden: true,
			disabled: true
		},
		{
			label: '星期三',
			value: dayjs().day(3).format('YYYY-MM-DD'),
			description: dayjs().day(3).format('MM月DD日'),
			hidden: true,
			disabled: true
		},
		{
			label: '星期四',
			value: dayjs().day(4).format('YYYY-MM-DD'),
			description: dayjs().day(4).format('MM月DD日'),
			hidden: true,
			disabled: true
		},
		{
			label: '星期五',
			value: dayjs().day(5).format('YYYY-MM-DD'),
			description: dayjs().day(5).format('MM月DD日'),
			hidden: true,
			disabled: true
		},
		{
			label: '星期六',
			value: dayjs().day(6).format('YYYY-MM-DD'),
			description: dayjs().day(6).format('MM月DD日'),
			hidden: greaterThanTargetDate,
			disabled: true
		},
		{
			label: '星期日',
			value: dayjs().day(7).format('YYYY-MM-DD'),
			description: dayjs().day(7).format('MM月DD日'),
			hidden: false,
			disabled: true
		}
	]
	let timeList = []
	list.forEach(item => {
		if (item.hidden) {
			timeList.push(item)
		}
	})
	return timeList
}
export function handleDate() {
	const today = dayjs().format('YYYY-MM-DD')
	const list = weekData()
	list.forEach((data) => (data.disabled = !dayjs(today).isSameOrBefore(data.value)))
	return list
}

export const times = [
	// {
	// 	label: '09:00',
	// 	value: 0,
	// 	disabled: false
	// },
	{
		label: '10:00',
		value: 1,
		disabled: false
	},
	// {
	// 	label: '14:00',
	// 	value: 2,
	// 	disabled: false
	// },
	// {
	// 	label: '15:00',
	// 	value: 3,
	// 	disabled: false
	// },
	{
		label: '16:00',
		value: 4,
		disabled: false
	}
]
export function handleTimes() {
	const today = dayjs().format('YYYY-MM-DD')
	times.forEach((data) => (data.disabled = dayjs().isAfter(dayjs(`${today} ${data.label}:00`))))
	return times
}
