import React from 'react'
import { LeftOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import './index.less'
export default function header(props) {
	const router = useNavigate()
	const handleArrowPage = () => {
		router('/')
	}
	if (props.arrow) {
		return (
			<div className='page-header'>
				<LeftOutline className='arrow' onClick={handleArrowPage} />
				<div className='header-name'>{props.title}</div>
			</div>
		)
	} else {
		return (
			<div className='page-header'>
				<span>{props.title}</span>
			</div>
		)
	}
}
