import { handleDate, times } from '@/common/data'
import { updateData } from '@/store/actions/common'
import { Button, Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import find from 'lodash/find'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header/index'
import WeekForm from '../../components/WeekForm'
import './index.less'
import Notice from "../../components/Notice";
const dateImg = require('@/assets/data.png')
const timeImg = require('@/assets/time.png')
const home = (props) => {
  const router = useNavigate()
  const weekData = handleDate()
  const [formData, setFormData] = useState({})
  const [timesList, setTimeList] = useState(times)
  // const [visible, setVisible] = useState(props.argess)
  useEffect(() => {
    if (props.selectValue?.date && props.selectValue?.date.length > 0) {
      const today = dayjs(props.selectValue.date[0]).format('YYYY-MM-DD')
      const list = times.map((data) => {
        return {
          ...data,
          disabled: (data.disabled = dayjs().isAfter(
            dayjs(`${today} ${data.label}:00`)
          ))
        }
      })
      setTimeList(list)
    }
  }, [])
  const handleData = (value) => {
    // 判断选择是否是今天
    if (value.date && dayjs().isSame(value.date, 'day')) {
      // 今天的话处理times数据
      const list = timesList.map((item) => {
        return {
          ...item,
          disabled: dayjs().isAfter(
            dayjs(`${value.date || props.selectValue.date[0]} ${item.label}:00`)
          )
        }
      })
      setTimeList(list)
    } else if (value.date) {
      const list = timesList.map((item) => {
        return {
          ...item,
          disabled: false
        }
      })
      setTimeList(list)
    }
    setFormData({ ...formData, ...value })
  }
  const handleSubmit = () => {
    if (
      props.selectValue.date.length > 0 &&
      props.selectValue.time.length > 0
    ) {
      const timeData = find(times, { value: props.selectValue.time[0] * 1 })

      props.updateData({
        date: props.selectValue.date.join(''),
        time: timeData.label
      })
      if (
        dayjs().isAfter(
          dayjs(`${props.selectValue.date.join('')} ${timeData.label}:00`)
        )
      ) {
        Toast.show({
          content: '当前时段超期,请选择其他时间段!'
        })
      } else {
        router('/verify')
      }
    } else {
      if (!formData.date) {
        Toast.show({
          content: '请选择参观日期'
        })
      } else if (!formData.time) {
        Toast.show({
          content: '请选择参观时间'
        })
      } else {
        if (formData.date.length > 0 && formData.time.length > 0) {
          props.updateData(formData)
          if (dayjs().isAfter(dayjs(`${formData.date} ${formData.time}:00`))) {
            Toast.show({
              content: '当前时段超期,请选择其他时间段!'
            })
          } else {
            router('/verify')
          }
        } else {
          Toast.show({
            content: '请选择参观时间'
          })
        }
      }
    }
  }
  return (
    <>
      <Header title='郑州眼健康科普馆'></Header>
      {/* 预约表单 */}
      <div className='reservationForm'>
        <div className='form-container-main'>
          <div className='formTitle'>
            <img className='icon' src={dateImg} alt='' />
            <span className='name'>参观日期</span>
          </div>
          <WeekForm
            options={weekData}
            defaultValue={props.selectValue?.date || []}
            name='date'
            handleData={handleData}
          ></WeekForm>
        </div>

        <div className='form-container-main'>
          <div className='formTitle'>
            <img className='icon' src={timeImg} alt='' />
            <span className='name'>参观时间</span>
          </div>
          <WeekForm
            options={timesList}
            name='time'
            defaultValue={props.selectValue?.time || []}
            handleData={handleData}
          ></WeekForm>
        </div>
        <div className='tips'>温馨提示:国家法定节假期不接受预约，谢谢配合!</div>
      </div>
      <div className='action'>
        <Button block color='primary' size='large' onClick={handleSubmit}>
          立即预约
        </Button>
      </div>
      {
        props.argess && <Notice/>
      }
    </>
  )
}
export default connect(
  (state) => ({
    visitorTimeOrDate: state.commonReducer.visitorTimeOrDate,
    selectValue: state.commonReducer.selectValue,
    argess: state.commonReducer.argess
  }),
  {
    updateData
  }
)(home)
