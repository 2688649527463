export const columns = [
	{
		label: '参观人',
		key: 'name',
		headerAlign: 'center',
		width: 50
	},
	{
		label: '人数',
		key: 'num',
		headerAlign: 'center',
		width: 30
	},
	{
		label: '预约电话',
		key: 'mobile',
		headerAlign: 'center',
		width: 85
	},
	{
		label: '预约时间',
		key: 'time',
		headerAlign: 'center',
		width: 80
	},
	{
		label: '取消预约',
		key: 'action',
		headerAlign: 'center',
		width: 50
	}
]
