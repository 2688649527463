import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { combineReducers } from 'redux'
// 引入redux-thunk，用于支持异步action
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import commonReducer from './reducer'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage/session'
const persistConfig = {
	key: 'common',
	storage,
	whitelist: ['commonReducer'] //设置SidebarCollapsedReducers数据持久化， 注意单词的拼写
}

const reducers = combineReducers({
	commonReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
const persisstore = persistStore(store)

// 导出
export { store, persisstore }
